.footer-wall {
    background-image: -moz-linear-gradient(to right, #027f35 0%, #fff45f 100%);
    background-image: -webkit-linear-gradient(to right, #027f35 0%, #fff45f 100%);
    background-image: -ms-linear-gradient(to right, #027f35 0%, #fff45f 100%);
    background-image: -o-linear-gradient(to right, #027f35 0%, #fff45f 100%);
    background-image: linear-gradient(to right, #027f35 0%, #fff45f 100%);
    height: 4px;
    margin-top: 2rem;
    overflow: hidden;
    max-width: 73rem;
    &:before {
        display: table;
        content: "";
        line-height: 0;
    }
}
.footer-wrapper {
    img {
        max-height: 100px;
    }
    padding: 10px 0 100px;
    @include breakpoint(medium) {
        padding: 30px 0 50px;
    }
    .large-8 {
        @include breakpoint(large) {
            padding-left: 0.9375rem;
        }
        @include breakpoint(large) {
            &.text-center {
                text-align:left;
            }
        }
    }
    .socials {
        a {
            display: inline-block;
            padding: 1rem 0.2rem;
            i {
                font-size: 2rem;
            }
            img {margin-top: -10px;}
        }
    }
    .large-2 {
        text-align: center;
        ul {
            list-style: none;
            display: inline-block;
            margin: 50px 0 0 0;
        }

        @include breakpoint(large) {
            padding-right: 0.9375rem;
            text-align: right;
            ul {
                margin-top: 0;
            }
        }
    }
}

.mobile-shortlinks {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: .25rem solid rgba(255, 255, 255, .5);
    .cell {
        text-align: center;
        border-right: 1px solid $white;
        &:last-child {
            border-right: none;
        }
        a {
            display: block;
            color: $white;
            font-family: $InfoDisplayWeb-Bold, sans-serif;
            text-transform: uppercase;
            font-size: rem-calc(15px);
            padding-top: 2.5rem;
            &.bgcolor1, &.bgcolor2, &.bgcolor3, &.bgcolor4 {
                background: $wohnen-color $wohnen-Image-dir no-repeat;
                background-size: 2rem;
                background-position: center .3rem;
            }
            &.bgcolor2 {
                background-color: $erlebnis-color;
                background-image: $erlebnis-Image-dir;
                background-size: 2.5rem;
            }
            &.bgcolor4 {
                background-color: $genossenschaft-color;
                background-image: $genossenschaft-Image-dir;
            }
        }

    }
}
