.with_breadcrumb_menu {
    .breadcrumb_menu {
        display: none;
        @include breakpoint(medium) {
            display: block;
        }
        ul.submenu {
            list-style: none;
            li {
                display: inline-block;
                a {
                    color: $white;
                    font-family: $InfoDisplayWeb-Bold;
                    padding: 5px 9px 3px;
                    text-transform: uppercase;
                    font-size: 13px;
                }
            }
            &.firstPageUl {
                margin-left: 0;
                li {
                    a {
                        padding-left: 35px;
                    }
                }
            }
            &#menu3 {
                li {
                    margin-right: 10px;
                    margin-bottom: 10px;
                    a {
                        background: $white;
                        color: $grey;
                        display: block;
                    }
                }
            }
        }
    }
    &.departmentUid-2, &.departmentUid-4, &.departmentUid-6, &.departmentUid-8 {
        .breadcrumb_menu ul.submenu {
            li {
                background: $wohnen-color;
            }
            &.firstPageUl li a {
                background: $wohnen-Image-dir no-repeat 2px 2px;
                background-size: 22px;
                display: block;
            }
            &#menu3 li {
                &:hover, &.active {
                    a {
                        background: $wohnen-color;
                        color: $white;
                    }
                }
            }
        }
    }
    &.departmentUid-4 .breadcrumb_menu ul.submenu {
        li {
            background: $erlebnis-color;
        }
        &.firstPageUl li a {
            background-image: $erlebnis-Image-dir;
        }
        &#menu3 li {
            &:hover, &.active {
                a {
                    background: $erlebnis-color;
                }
            }
        }
    }
    &.departmentUid-6 .breadcrumb_menu ul.submenu {
        li {
            background: $kultur-color;
        }
        &.firstPageUl li a {
            background-image: $kultur-Image-dir;
        }
        &#menu3 li {
            &:hover, &.active {
                a {
                    background: $kultur-color;
                }
            }
        }
    }
    &.departmentUid-8 .breadcrumb_menu ul.submenu {
        li {
            background: $genossenschaft-color;
        }
        &.firstPageUl li a {
            background-image: $genossenschaft-Image-dir;
        }
        &#menu3 li {
            &:hover, &.active {
                a {
                    background: $genossenschaft-color;
                }
            }
        }
    }
}
