
.ce-textpic {

    [data-ce-images="1"][data-ce-columns="2"] {
        width: 50%;
    }
    [data-ce-images="1"][data-ce-columns="3"] {
        width: 33%;
    }
    [data-ce-images="1"][data-ce-columns="4"] {
        width: 25%;
    }

    // multiple images in columns
    //[data-ce-images="2"][data-ce-columns="2"] .csc-textpic-imagecolumn,
    //[data-ce-images="3"][data-ce-columns="3"] .csc-textpic-imagecolumn,
    //[data-ce-images="5"][data-ce-columns="5"] .csc-textpic-imagecolumn {
    //    width: 100%;
    //    margin-right: 0;
    //    margin-bottom: 1%;
    //}
}
