.bw_bookingmanager {
    &__calendarMonth {
        background: #fff;
        &__navigation {
            padding: 1rem 1.5rem;
            h3 {
                margin-bottom: 0;
            }
        }
        &__body {
            background: $light-gray;
            min-height: rem-calc(278);
        }
    }
    &__week {
        margin-top: rem-calc(1);
        justify-content: space-between;
    }
    &__day {
        width: calc(14.28571% - 0.8571428571px) !important;
        background: #f3f3f3;
        color: $dark-gray;
        &--link {
            background: #FFF;
            color: $primary-color;
            &:hover {
                background: $wohnen-color;
            }
        }
        &.active {
            background: $primary-color;
            color: #FFF;
        }
        &--full {
            background: $alert-color;
            color: $body-font-color;
        }
        &--isInPast {
            background: #f3f3f3;
            color: $dark-gray;
        }
        &--isCurrentDay {
            background: #f3f3f3;
            box-shadow: inset 1px 0 0 $alert-color, inset -1px 0 0 $alert-color, inset 0 1px 0 $alert-color, inset 0 -1px 0 $alert-color;
            color: $alert-color;
        }
        &--isSelectedDay {
            background: #0078e6;
            color: #FFF;
            &.old{
                opacity: 0.5;
                cursor: pointer;
            }
            &:hover{
                background: #0364bd;
                color: #FFF;
            }
        }
    } // Time list after calendar partial with multiple timeslots per day
    &__dayList {
        margin: 1rem 0;
        &__day {
            display: none;
            &.active {
                display: block;
            }
        }
        .button {
            margin-right: rem-calc(5);
            &.active {
                background-color: $primary-color;
            }
        }
    } // Skater calendar
    &__calendarDayList {
        background: $light-gray;
        border: 1px solid $light-gray;
        border-bottom: 0;
        &__row {
            margin-bottom: 1px;
            &>.cell:nth-child(2) {
                margin-left: 1px;
                margin-right: 1px;
            }
        }
        &__date {
            padding-bottom: 0.1rem;
            background: rgba($primary-color, 0.3);
            strong {
                display: block;
                font-size: 1.4rem;
                line-height: 1rem;
                margin-top: 0.6rem;
            }
        }
        span {
            margin-left: rem-calc(10);
        }
    }
    &__freeWeightBulled {
        display: inline-block;
        background: rgba(#000000, 0.5);
        padding: rem-calc(5);
        font-weight: bold;
        text-align: center;
        border-radius: 100%;
        min-width: 2.1rem;
        color: #FFF;
        &.large {
            border-radius: 20px;
            padding: rem-calc(5 10);
            font-weight: normal;
        }
    }
}

#bw_bookingmanager,
#bw_bookingmanager__newEntry {
    div.text-right.middle,
    label.middle {
        line-height: 1;
        padding-top: 0.5rem;
        input {
            float: left;
            margin: rem-calc(0 10 33 5);
        }
    }
    h3 i,
    .button i,
    .callout p i {
        margin-right: rem-calc(5);
    }
    label.inline {
        display: inline;
    }
    .fa-spinner,
    .callout.alert {
        display: none;
    }
    &.loading,
    &.error {
        position: relative;
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($yellow-background-color, 0.8);
            z-index: 1;
        }
    }
    &.loading {
        .fa-spinner {
            display: inline-block;
            position: absolute;
            left: 50%;
            font-size: rem-calc(40);
            margin-left: rem-calc(-20);
            top: rem-calc(180);
            color: $primary-color;
            z-index: 2;
        }
    }
    &.error {
        .fa-spinner {
            display: none;
        }
        .callout.alert {
            display: block;
            position: absolute;
            left: 10%;
            width: 80%;
            top: rem-calc(120);
            z-index: 2;
        }
    }
}

textarea.kita-questions {
    min-height: 120px;
}
