#body-44 {
    .wrapper {
        .departmentUid-6 {
            .info_wrapper {
                ul {
                    li {
                        list-style: none;
                        &:before {
                            content: "\f005";
                            font-family: "Font Awesome 5 Free";
                            display: inline-block;
                            margin-left: -1.3em;
                            width: 1.3em;
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}

.inlineList {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
}
