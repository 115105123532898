.form_wrapper {
    padding: 10px 15px;
    text-align: left;
    background: $wohnen-color;
    font-family: $InfoDisplayWeb-Medium;
    p {
        color: $white;
        line-height: 18px;
    }
    .boldText {
        font-weight: bold;
        color: $white;
        line-height: 18px;

    }
    h3 {
        color: $white !important; // @fixme
        font-size: rem-calc(23px);
        line-height: rem-calc(20px);
        font-family: $InfoDisplayWeb-Bold;
    }
    form {
        label {
            width: 100%;
            text-align: left;
            color: $white;
            line-height: 1;
        }
        select {
            margin: 0 0 10px;
        }
        .button {
            margin-bottom:0;
            padding:5px 10px;
            width: 100%;
            margin-top: -2px;
        }
        .grid-container {
            .grid-x {
                .withPad {
                    .cell {
                        p {
                            align-self: flex-end;
                            margin-top: 8px;
                            text-align: center;
                            color: $black;
                        }
                    }

                    @include breakpoint(medium up) {
                        padding-right: 20px;
                        label {
                            width: 100%;
                            text-align:left;
                        }
                        .cell {
                            p {
                                align-self: flex-end;
                                margin-top: 8px;
                            }
                        }
                    }

                    @include breakpoint(large) {
                        padding-right: 20px;
                        //label {
                        //    width: 100%;
                        //    text-align:left;
                        //}
                        //.cell {
                        //    p {
                        //        align-self: flex-end;
                        //        margin-top: 8px;
                        //    }
                        //}
                        &:nth-child(2) {
                            padding-right: 0;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
}

.parsley-errors-list {
    color: $input-error-color;
}
