
h1 {
    font-size: rem-calc(24px);
    text-transform: uppercase;
    font-family: $InfoDisplayWeb-Bold;

    .departmentUid-2 & {
        color: $wohnen-color;
    }
    .departmentUid-4 & {
        color: $erlebnis-color;
    }
    .departmentUid-6 & {
        color: $kultur-color;
    }
    .departmentUid-8 & {
        color: $genossenschaft-color;
    }
    .frame-yellow & {
        color: #FFF;
        background: $erlebnis-color;
        padding: rem-calc(5 15);
        line-height: 1.2;
    }
}
h2 {
    font-size: rem-calc(22px);
}

h3 {
    font-size: rem-calc(20px);
}

h4 {
    font-size: rem-calc(19px);
    font-style: italic;
}

h5 {

}

.text-center {
    text-align: center;
}
