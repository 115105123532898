.card-view {
    .cell {
        overflow: hidden;
        a {
            display: block;
            .img-wrap, .header {
                width: 100%;
                height: auto;
            }
            .img-wrap {
                height: rem-calc(185px);
                overflow: hidden;
                background-color: #ffffff;
                background-position: center,center;
                background-size: cover;
                transition: all 1s ease;
                span::before {
                    font-size: rem-calc(0px);
                    color: transparent;
                    content: '\f14d';
                    font-family: 'Font Awesome 5 Free';
                    position: absolute;
                    margin-left: 45%;
                    margin-top: 4rem;
                    z-index: -1;
                    @include breakpoint(large) {
                        margin-left: 9rem;
                    }
                }
            }
            .header {
                padding: 1rem;
                vertical-align: top;
                h3 {
                    color: $white;
                    font-family: $InfoDisplayWeb-Bold;
                }
            }
            &:hover {
                color: $white;
                h3 {
                    text-decoration: underline;
                }
                .img-wrap {
                    filter: grayscale(.85);
                    background-color: $white;
                    span::before {
                        font-size: rem-calc(40px);
                        color: #068030;
                        -webkit-transition: all 0.5s ease;
                        transition: all 0.5s ease;
                        z-index: 3;
                    }
                }
            }
        }
    }
}

.abstract-list-view {
    .cell {
        @include breakpoint(medium) {
            height: rem-calc(200px) !important;
            a {
                .img-wrap, .header {
                    width: 50%;
                    height: rem-calc(200px);
                    display: inline-block;
                }
                .img-wrap {
                    img {
                        min-height: rem-calc(200px);
                    }
                }
            }
        }
    }
}

.departmentUid-2 .abstract-list-view .cell { background: $wohnen-color; }
.departmentUid-4 .abstract-list-view .cell { background: $erlebnis-color; }
.departmentUid-6 .abstract-list-view .cell { background: $kultur-color; }
.departmentUid-8 .abstract-list-view .cell { background: $genossenschaft-color; }
