
.ce-align-left { text-align: left; }
.ce-align-center { text-align: center; }
.ce-align-right { text-align: right; }

.ce-table td, .ce-table th { vertical-align: top; }

.ce-textpic, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div { overflow: hidden; }

.ce-left .ce-gallery, .ce-column { float: left; }
.ce-center .ce-outer { position: relative; float: right; right: 50%; }
.ce-center .ce-inner { position: relative; float: right; right: -50%; }
.ce-right .ce-gallery { float: right; }

.ce-gallery figure { display: table; margin: 0; }
.ce-gallery figcaption { display: table-caption; caption-side: bottom; }
.ce-gallery img { display: block; margin-bottom:1rem;}

iframe, video, audio {
    border-width: 0;
    max-width:100%;
}
.responsive-embed, .flex-video {
    padding-bottom: 69%;
}
.ce-border img,
.ce-border iframe {
    border: 0;
    padding: 0;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
    margin-bottom: 1rem;
}
.ce-intext.ce-right .ce-gallery {
    margin-left: 1rem;
}
.ce-intext.ce-left .ce-gallery {
    margin-right: 1rem;
}
.ce-below .ce-gallery {
    margin-top: 1rem;
}

.ce-column { margin-right: 1rem;
}
.ce-column:last-child { margin-right: 0; }

.ce-row {
    margin-bottom: 1rem;
}
.ce-row:last-child { margin-bottom: 0; }

.ce-above .ce-bodytext { clear: both; }

.ce-intext.ce-left ol, .ce-intext.ce-left ul { padding-left: 40px; overflow: auto; }

.ce-uploads { margin: 0; padding: 0; }
.ce-uploads li { list-style: none outside none; margin: 1em 0; }
.ce-uploads img { clear: left; float: left; padding-right: 1em; vertical-align: top; }
//.ce-uploads span { display: block; }



$frame-space-extra-small: 1rem;
$frame-space-small: 1.5rem;
$frame-space-medium: 2rem;
$frame-space-large: 3rem;
$frame-space-extra-large: 4rem;

.frame-space-before-extra-small { margin-top: $frame-space-extra-small; }
.frame-space-after-extra-small { margin-bottom: $frame-space-extra-small; }
.frame-space-before-small { margin-top: $frame-space-small; }
.frame-space-after-small { margin-bottom: $frame-space-small; }
.frame-space-before-medium { margin-top: $frame-space-medium; }
.frame-space-after-medium { margin-bottom: $frame-space-medium; }
.frame-space-before-large { margin-top: $frame-space-large; }
.frame-space-after-large { margin-bottom: $frame-space-large; }
.frame-space-before-extra-large { margin-top: $frame-space-extra-large; }
.frame-space-after-extra-large { margin-bottom: $frame-space-extra-large; }

/* Frame */
.frame-ruler-before:before { content: ''; display: block; border-top: 1px solid rgba(0,0,0,0.25); margin: 2rem 0; }
.frame-ruler-after:after { content: ''; display: block; border-bottom: 1px solid rgba(0,0,0,0.25); margin: 2rem 0; }
//.frame-indent { margin-left: 15%; margin-right: 15%; }
//.frame-indent-left { margin-left: 33%; }
//.frame-indent-right { margin-right: 33%; }

.frame-yellow{
    background: $yellow-background-color;
    margin-bottom: 2rem;
    .ce-textpic{
        padding: 1rem 2rem;
    }
}

.image-caption {
    position: absolute;
    bottom: 1rem;
    padding: 0.5rem 1rem;
    background: #cccccc;
    opacity: 0.8;
}

a[href*=".pdf"], a[href$='.xls'], a[href$='.csv'], a[href$='.xlw'], a[href$='.xlt'], a[href$='.doc'], a[href$='.rtf'], a[href$='.txt'], a[href$='.zip'], a[href$='.rar'], a[href$='.gzip'] {
    &::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f063'; // .pdf
        margin-right: .2rem;
        text-decoration: none;
    }
    figure > &::before, &.no-file-icon::before {
        content: '';
        margin-right: 0;
    }
}

/* Excel-Links */
a[href$='.xls'], a[href$='.csv'], a[href$='.xlw'], a[href$='.xlt'] {
    &::before {
        content: '\f1c3';
    }
}
/* Word-Links */
a[href$='.doc'], a[href$='.rtf'], a[href$='.txt'] {
    &::before {
        content: '\f1c2';
    }
}
/* Zip-Links */
a[href$='.zip'], a[href$='.rar'], a[href$='.gzip'] {
    &::before {
        content: '\f1c6';
    }
}

td {
    vertical-align: top;
}
