.bwg_navigation {
    padding-top: 20px;
    padding-bottom: 20px;
    .title-bar {
        position: relative;
        border:1px solid $primary-color;
        .menu-icon {
            text-align: center;
            height:25px;
            width: 100%;
            left:0;
            right:0;
            margin-left:0;
            margin-right:0;
            margin-bottom:0;
            &:after {
                display: none;
            }
        }
        .title-bar-title {
            width: 100%;
            text-align: center;
        }
        &:hover {
            background: $white;
            .menu-icon  {
                color: $primary-color;
            }
        }
    }
    .logos {
        display: flex;
        align-items: center;
        align-content: space-between;
    }
    .top-bar {
        .top-bar-right {
            ul.dropdown.menu > {
                width: 100%;
                li {
                    display: block;
                    width: 100%;
                    position:relative;
                    &.bgcolor1 {
                        background:$wohnen-color;
                        > a {
                            background:$wohnen-Image-dir
                        }
                        ul.menu.vertical.submenu {
                            li {
                                &:hover {
                                    background:$wohnen-color;
                                    a {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                    &.bgcolor2 {
                        background:$erlebnis-color;
                        > a {
                            background:$erlebnis-Image-dir;
                        }
                        ul.menu.vertical.submenu {
                            li {
                                &:hover {
                                    background:$erlebnis-color;
                                    a {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                    &.bgcolor3 {
                        background:$kultur-color;
                        > a {
                            background:$kultur-Image-dir
                        }
                        ul.menu.vertical.submenu {
                            li {
                                &:hover {
                                    background:$kultur-color;
                                    a {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                    &.bgcolor4 {
                        background:$genossenschaft-color;
                        > a {
                            background:$genossenschaft-Image-dir;
                        }
                        ul.menu.vertical.submenu {
                            li {
                                &:hover {
                                    background:$genossenschaft-color;
                                    a {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                    a.image {
                        color: white;
                        text-transform: uppercase;
                        width: 100%;
                        display: block;
                        font-family: $InfoDisplayWeb-Bold, sans-serif;
                        padding: .5rem 1rem .5rem 50px;
                        font-size: 17px;
                        background-repeat: no-repeat;
                        background-size: 25px;
                        background-position: 10px center;
                        &:after {
                            display: none;
                        }
                        > span {
                            position: absolute;
                            width: 100%;
                            height: 0;
                            left: 0;
                            top: 0;
                            border-bottom: 17px solid #feed01;
                            border-top: 17px solid #feed01;
                            transition: .4s;
                            opacity: 0;
                        }
                    }
                    ul.menu.vertical.submenu {
                        right:auto;
                        left:0;
                        width: 100%;
                        background:white;
                        li {
                            border-bottom:1px solid rgba(102, 102, 102, 1);
                            a {
                                color: $text-color;
                                font-size: 12px;
                                font-family: 'Open Sans', sans-serif;
                                text-align: center;
                                text-transform: uppercase;
                                line-height: 20px;
                                padding: 5px 10px;
                            }
                            &:last-child {
                                border-bottom:$yellow-background-color 5px solid;
                            }
                        }
                    }
                    &:hover, &.active {
                        > a {
                            > span {
                                border-width: 4px;
                                top: -4px;
                                opacity: 1;
                                height: 38px;
                                &:before {
                                    content: "";
                                    border-color: $yellow-background-color transparent transparent;
                                    position: absolute;
                                    left: 50%;
                                    margin-left: 0;
                                    top: 0;
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 0;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }

            @include breakpoint(mediumplus) {
                > ul.dropdown.menu {
                    background-color: transparent;
                    height: auto;
                    overflow: visible;
                    display: inline-block;
                    position: relative;
                    clear: both;
                    > li {
                        width: 150px;
                        height: 140px;
                        padding: 0;
                        vertical-align: middle;
                        margin: 0 4px 0 0;
                        display: inline-block;
                        text-align: center;
                        &:last-child {
                            margin: 0 0 0 0;
                        }
                        > a.image {
                            padding: 86px 0 0 0;
                            width: 150px;
                            height: 140px;
                            background-size: 50px;
                            background-position: center 25px;
                            &.mainnav_haende {
                                background-size: 60px;
                            }
                            > span {
                                border-bottom: 70px solid #feed01;
                                border-top: 70px solid #feed01;
                            }
                        }
                        &:hover, &.active {
                            > a {
                                > span {
                                    border-width: 6px;
                                    top: 0;
                                    opacity: 1;
                                    height: 140px;
                                    &:before {
                                        content: '';
                                        border-color: $yellow-background-color transparent transparent;
                                        position: absolute;
                                        left: 50%;
                                        top: 0;
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        transition: 0.5s;
                                        border-width: 5px;
                                        margin-left: -5px;
                                    }
                                }
                            }
                        }
                        ul.menu.vertical.submenu {
                            min-width: 150px;
                            width: 150px;
                        }
                    }
                }
            }

            @include breakpoint(large) {
                > ul.dropdown.menu {
                    > li {
                        margin: 0 15px 0 0;
                    }
                }
            }
        }
    }
    .navigation_images {
        .left {
            text-align: center;
            img {
                width: 70%;
                @include breakpoint(medium) {
                    width: 90%;
                    display: block;
                }
            }
        }
        .right {
            text-align: center;
        }
    }
}
