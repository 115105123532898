.bw-openimmo__list {
    background: #FFF;
    display: grid;
    grid-template-columns: 100%;
    @include breakpoint(large) {
        grid-template-columns: 60% 40%;
    }
}

.bw-openimmo__error {
    background: #FFF;
    padding: 2rem;
}

.openimmo__list__objects {
    padding: 1rem;
}

.openimmo__list__item {
    display: block;
    box-shadow: 0 2px 3px rgba(#000, 0.2);
    margin-bottom: 1rem;
    transition: all 0.2s ease;

    &:hover {
        background: $primary-color;

        * {
            color: #FFF;
        }

        box-shadow: 0 2px 4px 1px rgba(#000, 0.1);
        transform: scale(1.01);
        transition: box-shadow 0.4s ease, transform 0.4s ease;
    }
}

.openimmo__list__image {
    margin-bottom: 0.5rem;

    img {
        max-width: 100%;
    }
}

.bw-openimmo__list__text {
    padding: 1rem 1rem 0.5rem 1rem;
    color: $body-font-color;

    p span + span:before {
        content: '•';
        display: inline;
        margin: 0 5px;
    }
}

.bw-openimmo__search {
    background: #FFF;
    z-index: 3;

    .sticky-search & {
        position: fixed;
        width: calc(100% - 20px);
        top: 0;
        @include breakpoint(large) {
            left: auto;
            width: 1170px;
        }
    }
}

body.sticky-search {
    padding-top: 60px;
}

.bw-openimmo__search-bar {
    padding: 15px;
    box-shadow: 0 3px 5px rgba(#000, 0.2);
    display: grid;
    position: relative;
    grid-gap: 1rem;

    @include breakpoint(medium) {
        padding: 15px 20px;
        grid-template-columns: auto auto;
    }

    h2 {
        margin-bottom: 0;
    }
}

.bw-openimmo__search-buttons {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;

    @include breakpoint(medium) {
        text-align: right;
        grid-gap: 2rem;
    }
}

.bw-openimmo__search-form {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    width: calc(100% - 20px);
    z-index: 2;

    @include breakpoint(large) {
        left: auto;
        width: 1170px;
    }

    .open-search & {
        max-height: none;
    }
}

.bw-openimmo__list__map {
    position: relative;

    #map {
        display: none;
        @include breakpoint(large) {
            display: block;
            height: 100vh;
            z-index: 1;
            .sticky-search & {
                top: 0px;
                position: fixed !important;
                width: 468px;
                height: 100vh;
            }
        }
    }
}

.marker-window {
    img {
        margin-bottom: 5px;
    }
}

.bw-openimmo__single {
    background: #FFF;
    padding: 2rem 1rem;

    &--green {
        background: $primary-color;
        color: #FFF;
        position: relative;
        padding-top: 5rem;

        &--image {
            padding-top: 10rem;
        }

        a {
            color: #FFF;
            text-decoration: underline;
        }
    }
}

.bw-openimmo__contact-button {
    position: absolute;
    width: 100%;
    left: 0;
    display: grid;
    justify-content: center;
    justify-items: center;
    top: -0.5rem;

    .bw-openimmo__single--green--image & {
        top: -4rem;
    }

    span {
        display: none;
        height: 130px;
        overflow: hidden;
        border-radius: 100%;
        justify-content: center;
        border: 2px solid #FFF;

        .bw-openimmo__single--green--image & {
            display: inline-block;
        }
    }

    a {
        background: #FFF;
        display: inline-block;
        padding: 10px 15px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 2px rgba(#000, 0.3);
        color: $primary-color;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.2s ease;
        margin-top: -1rem;

        i {
            position: relative;
            top: 1px;
            margin-right: 4px;
        }

        &:hover {
            transform: translate3d(0, -2px, 0);
            box-shadow: 0 4px 5px 3px rgba(#000, 0.35);
        }
    }
}

.bw-openimmo__single-images {
    position: relative;
    margin-bottom: 2rem;

    ul {
        position: absolute;
        bottom: 0;
        list-style: none;
        width: 100%;
        text-align: center;
    }

    ul li {
        display: inline-block;
    }

    ul li a {
        display: inline-block;
        border: 1px solid #FFF;
        margin: 5px;

        &:hover {
            border-color: $primary-color;
        }
    }
}

.bw-openimmo__single-text {
    margin-bottom: 2rem;

    h4 {
        font-style: normal;
    }

    h3.small-margin-top {
        @include breakpoint(small only) {
            margin-top: 2rem;
        }
    }

    &--teaser {
        font-size: 1.4rem;
        margin: 2rem 0;

        p {
            line-height: 1;
            display: inline-block;
            color: $primary-color;
            @include breakpoint(medium) {
                padding: 30px;
                background: rgba(#000, 0.05);
                border-radius: 10px;
            }
        }

        strong {
            font-size: 1rem;
            opacity: 0.7;
            color: $body-font-color;
        }
    }

    &--lines {
        margin-bottom: 2rem;

        .grid-x {
            border-bottom: 1px solid #CCC;
        }
    }
}

body.open-searchAgent, body.open-contact {
    overflow-y: hidden;
}

.bw-openimmo__lightbox {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #FFF;
    z-index: 99;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .open-contact &#contact, .open-searchAgent &#searchAgent {
        display: block;
    }

    h2:empty {
        display: none;
    }

    &__close {
        background: #eee;
        color: $primary-color;
        position: absolute;
        right: 0;
        font-size: 1.2rem;
        padding: 1.2rem 1.4rem;
        transition: background-color 0.3s ease;
        line-height: 0;

        &:hover {
            background: #ddd;
        }
    }

    &__container {
        padding: 3rem 1rem;
    }

    .has-error input {
        border-color: #c3b8a6;
    }

    span.error {
        @extend .callout;
        @extend .warning;
        display: block;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        border-color: #c3b8a6;
    }

    .btn {
        margin-top: 2rem;
        @extend .button;
        @extend .expanded;
    }
}

#bwOpenimmoImportImmosolveSearchAgentForm-region {
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    margin-bottom: 1rem;
}

.slider-values {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
}

.slider {
    .slider-handle {
        border-radius: 100%;
        background-color: #333;
    }

    .slider-fill {
        background-color: $primary-color;
    }
}

#bwOpenimmoImportImmosolveSearchAgentForm-step1 legend, #bwOpenimmoImportImmosolveSearchAgentForm-step2 legend {
    font-size: 1.3rem;
    margin: 0 0 1rem 0;
    display: grid;
    grid-template-columns: 3rem auto;
    align-items: center;

    &:before {
        content: '1';
        background: $primary-color;
        color: #FFF;
        padding: 5px;
        border-radius: 100%;
        height: 2rem;
        margin-right: 1rem;
        display: grid;
        justify-content: center;
        align-content: center;
    }
}

#bwOpenimmoImportImmosolveSearchAgentForm-step2 legend:before {
    content: '2';
}

.openimmo__list__sorting {
    margin: 1rem 2rem;
}

#change-sorting {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    display: inline-block;
    transition: transform 0.3s ease;
    transform-origin: 10px 15px;
    &:hover {
        transform: rotate(180deg);
    }
}

