#tx_cookies {
    position: fixed;
    bottom: 0px;
    left: 0;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    z-index: 1000;
    h3,h4 {
        color:$genossenschaft-color;
    }
}
#tx_cookies_inner, .typo3-messages {
    background: rgba(254, 244, 95,0.9);
    bottom: 0px;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    max-width: 500px;
    width: 90%;
    max-height:450px;
    overflow-y: scroll;
    padding: 3rem 2rem 1rem 2rem;
    margin: 0;
    li {
        list-style:none;
    }
    @include breakpoint(medium) {
        max-height:700px;
        overflow-y: hidden;
    }
}
#tx_cookies button, #tx_cookies input[type="submit"] {
    border: 0;
    background: #8cb123;
    cursor: pointer;
    color: #ffffff;
    font-family: "InfoDisplayWeb-Bold";
    font-size: 1.1rem;
    border-radius: 3px;
    padding: .5rem;
    margin-bottom:1rem;
}
#tx_cookies #tx_cookies_close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    margin: 0;
}
#tx_cookies_showPermanent {
    display: none !important;
    visibility: hidden;
}
#cookies_settings_button {
    color:$genossenschaft-color;
    cursor: pointer;
}
