.orbit {
    margin-bottom: 2rem;
    .orbit-wrapper {
        background: url('../Images/loading.gif') no-repeat center center;
        .mediaelement-image {
            img {
                width: auto;
            }
        }
        .mediaelement-video {
            iframe {
                width: 100%;
                min-height:450px;
            }
        }
        .orbit-previous, .orbit-next {
            background-color: rgba(10,10,10,0.2);
            &:hover, &:active, &:focus {
                background-color: rgba(10,10,10,0.5);
            }
        }
        .orbit-container {
            background: #ffffff;
        }
    }
}

.bwg_slider {
    padding-bottom: 30px;
    @include breakpoint(large) {
        .grid-x > .large-8 {
            margin-right: 0;
            width: calc(66.66667% - 0.9375rem);
        }
        .grid-x > .large-4 {
            margin-left: 0;
            width: calc(33.33333% - 0.9375rem);
        }
    }
    .orbit {
        display: none;
        @include breakpoint(medium) {
            display: block;
        }
        figcaption {
            background:transparent;
            width: 80%;
            padding: 0;
            -vendor-animation-delay: 1s;
            -vendor-animation-duration: 3s;
            animation-delay:1s;

            a {
                font-family: $InfoDisplayWeb-Bold;
                background: $yellow-semitransparent-color;
                color: $primary-color;
                font-size: 22px;
                padding: 10px 25px 10px 30px;
                margin-bottom: 30px;
                width: 100%;
                line-height: 1.3;
                display: block;
            }
        }
    }
    .form_wrapper, .frame {
        height: 100%;
    }
}
