main {
    .info_wrapper {
        background: $white;
        padding: 25px;
        font-family: 'Noto Serif', serif;
        border-top: 5px solid $wohnen-color;
        .contactperson {
            .contactperson_single {
                margin-bottom: 20px;
                p {
                    margin-left: 20px;
                }
                .email {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    max-width: 100%;
                }
            }
        }

        span.category {
            font-family: $InfoDisplayWeb-Medium;
            text-transform: uppercase;
            color: $grey;
        }

        .ce-image {
            margin-bottom: 1rem;
        }

        video {
            width: 100%;
            max-width: 100%;
            display: block;
            margin-bottom: 2rem;
        }
    }

    &.departmentUid-2 {
        .info_wrapper {
            border-top-color: $wohnen-color;
        }
    }
    &.departmentUid-4 {
        .info_wrapper {
            border-top-color: $erlebnis-color;
        }
    }
    &.departmentUid-6 {
        .info_wrapper {
            border-top-color: $kultur-color;
        }
    }
    &.departmentUid-8 {
        .info_wrapper {
            border-top-color: $genossenschaft-color;
        }
    }
}

.left {
    position: relative;
    .frame-type-html {
        position: absolute;
        top: 0;
        left: 0;
    }
}
