.sidebarH3 {
    background: $yellow-background-color;
    color: $primary-color;
    font-size: rem-calc(24px);
    padding: 6px 15px 3px;
    margin-bottom: 0;
    font-family: $InfoDisplayWeb-Bold, sans-serif;
    text-align: left;
}

.sidebar {
    margin-top: 30px;
    @include breakpoint(large) {
        margin-top: 0;
        > .grid-container.full {
            padding-left: 1rem;
        }
    }
    &.no-padding > div.grid-container {
        padding:0;
    }
    .socialConsultation {
        .oneColumnGrid {
            &, h3 {
                background: $kultur-color;
                padding-bottom: rem-calc(10px);
                &, a {
                    color: $white;
                }
                a {
                    text-decoration: underline;
                }
            }
            h4 {
                font-size: rem-calc(16px);
                text-transform: uppercase;
                font-weight: bold;
            }
            p {
                margin-bottom: .5rem;
                line-height: 1.4;
            }
        }
    }

    .showCaseBar {
        margin-top: 5.7rem;
        .oneColumnGrid { // this is a little bit hacky… ;-(
            padding: 15px;
        }
        .angebote {
            list-style: none;
            margin-top: 0;
            li {
                @extend .sidebarH3;
                padding: 0;
                &::before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f061";
                    margin-right: .5rem;
                }
            }
        }
    }

    .oneColumnGrid {
        background: $yellow-background-color;
        margin-bottom: 30px;
        text-align: left;
        h3 {
            @extend .sidebarH3;
        }
        .tx-cal-controller {
            a .summary::after {
                content: "\f101";
                font-family: "Font Awesome 5 Free";
                font-style: normal;
                font-variant-caps: normal;
                font-variant-ligatures: normal;
                font-weight: 900;
                height: 12px;
                line-height: 12px;
                text-rendering: auto;
                width: 12px;
                -webkit-font-smoothing: antialiased;
                color:$genossenschaft-color;
                margin-left:0.5rem;
            }
            .view-header {
                background: $white;
                position: relative;
                text-align: center;
                .previous-button {
                    position: absolute;
                    left: 10px;
                    margin-top: 0.5rem;
                }
                .next-button {
                    position: absolute;
                    right: 10px;
                    margin-top: 0.5rem;
                }
                h2 {
                    font-size: 16px;
                    font-family: $InfoDisplayWeb-Bold, sans-serif;
                    padding-top: 8px;
                }
                a {
                    svg {
                        margin-top: 11px;
                        color: $primary-color;
                        font-size: 18px;
                    }
                }
                table.month-large {
                    clear: both;
                    background-color: $white;
                    font-family: $InfoDisplayWeb-Medium, sans-serif;
                    position: relative;
                    border-collapse: separate;
                    border-spacing: 1px;
                    thead {
                        border: none;
                        border-top: 1px solid lightgrey;
                        tr {
                            th {
                                background: $white;
                                &.month-weeknums.monthCorner {
                                    display: none;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            display: none;
                            &.days {
                                display: table-row;
                                height: 51px;
                            }
                            th {
                                &.month-weeknums {
                                    display: none;
                                }
                            }
                            td {
                                background: $white;
                                border: 0px solid $white;
                                font-size:14px;
                                text-align:center;
                                position:relative;
                                padding-top: 16px;
                                &.eventDay {
                                    background: $wohnen-color;
                                }
                                &.monthOff {
                                    color: #888;
                                }
                                &.monthToday {
                                    background: $primary-color;
                                }
                                a {
                                    &.eventLink {
                                        width: 100%;
                                        position: absolute;
                                        left: 0;
                                        height: 100%;
                                        top: 0;
                                        text-align: center;
                                        padding-top: 20px;
                                        background: transparent;
                                        font-size: 14px;
                                        color: $black;
                                        font-family: $InfoDisplayWeb-Medium, sans-serif;
                                        td {
                                            &.empty {
                                                display: none;
                                            }
                                        }
                                        .eventContainer {
                                            td {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                &:hover {
                                    background: #cccccc;
                                }
                            }
                        }
                    }
                }
                .appending {
                    background:$yellow-background-color;
                    text-align: left;
                    padding:15px;
                    .grid-x {
                        margin-bottom: 1.05rem;
                        div, span {
                            color: $black;
                            font-family: $InfoDisplayWeb-Medium, sans-serif;
                            &.calTitle {
                                font-family: $InfoDisplayWeb-Bold, sans-serif;
                                p {
                                    font-family: $InfoDisplayWeb-Medium, sans-serif;
                                    margin-bottom: 0;
                                }
                                .default_catheader_text {
                                    display: block;
                                }
                                .default_catheader_allday {
                                    background:transparent;
                                    border:none;
                                    font-family: $InfoDisplayWeb-Medium, sans-serif;
                                }
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .frame-type-textmedia {
            padding: 0 15px;
        }
        .hotline_sidebar {
            margin-top: rem-calc(10px);
            padding: rem-calc(10px 15px);
            background: $primary-color;
            &, & a {
                color: $white;
                &:hover {
                    color: $yellow-background-color;
                    .fa-fire {
                        color: red;
                    }
                }
            }
            .fa-fire {
                font-size: rem-calc(50px);
                margin: rem-calc(10px 40px 0 0);
                display: block;
                float: left;
            }
            p {
                font-size: 1.2rem;
                line-height: 1.2;
                font-family: $InfoDisplayWeb-Bold;
            }
        }
        hr {
            border-bottom: 1px solid $primary-color;
            margin: 10px 0;
        }
        p {
            margin-bottom: 0;
        }
    }
}
