@import "foundation";
@import "settings";
@include foundation-everything();
@import "motion-ui";
@include motion-ui-transitions;
@include motion-ui-animations;
@import "_fonts";

//@import "animate.min.css";

body {
    background: url(../Images/bg_full.png);
    .wrapper {
        background: url(../Images/bg_top.jpg) no-repeat;
    }
}

@import "modules/topNavigation";
@import "modules/breadcrumb";
@import "modules/fluid_styled_content";
@import "modules/slider";
@import "modules/news";
@import "modules/menu-abstract";
@import "modules/withoutNews";
@import "modules/form";
@import "modules/sidebar";
@import "modules/footer";
@import "modules/gridelements";
@import "modules/specialLists";
@import "modules/imageRendering";
@import "modules/typography";
@import "modules/tx_cookies";
@import "modules/extBwBookingManager";
@import "modules/extBwOpenimmoBwg";
