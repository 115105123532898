.news {
    .news-list-view {
        @include breakpoint(medium) {
            .article.articletype-0.card {
                width: 47%;
                &:nth-child(odd) {
                    margin-right: 30px;
                }
            }
        }
        .header {
            h2 {
                color: $white;
                font-size: rem-calc(14);
                text-transform:uppercase;
            }
        }
        .cat-1 {
            background: $wohnen-color;
        }
        .cat-25 {
            background: $genossenschaft-color;
        }
        .cat-3 {
            background: $kultur-color;
        }
        .cat-4 {
            background: $erlebnis-color;
        }

        .page-navigation {
            width: 100%;
            p {
                width: 100%;
                text-align: right;
            }
            ul {
                display:block;
                width: auto;
                margin:0 auto;
                text-align: center;
                li {
                    background: $white;
                    display: inline-block;
                    margin-right: rem-calc(10);
                    padding: rem-calc(4) rem-calc(10);
                    &.current {
                        color: $primary-color;
                    }
                    a {
                        color: #666;
                    }
                }
            }
        }
    }
    &.news-single {
        padding: rem-calc(25);
        background: $white;
        .article {
            .news_date {
                margin-bottom: 0;
                time {
                    color:#888;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-family: $InfoDisplayWeb-Bold;
                }
            }
            h3 {
                text-transform: uppercase;
                font-size: rem-calc(24);
                font-family: $InfoDisplayWeb-Bold;
            }
            .news-text-wrap {
                margin-top: rem-calc(30);
                p {
                    font-family: 'Noto Serif', serif;
                }
            }
        }
    }
}

.cat-1 {
    .news .news-list-view .article, .news-backlink-wrap a {
        background: $wohnen-color;
    }
}
.cat-25 {
    .news .news-list-view .article, .news-backlink-wrap a {
        background: $genossenschaft-color;
    }
}
.cat-3 {
    .news .news-list-view .article, .news-backlink-wrap a {
         background: $kultur-color;
    }
}
.cat-4 {
    .news .news-list-view .article, .news-backlink-wrap a {
        background: $erlebnis-color;
    }
}
