//Fonts

/* noto-serif-regular - latin */
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Serif'), local('NotoSerif'),
       url('../Fonts/noto-serif-v6-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../Fonts/noto-serif-v6-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-italic - latin */
@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 400;
  src: local('Noto Serif Italic'), local('NotoSerif-Italic'),
       url('../Fonts/noto-serif-v6-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../Fonts/noto-serif-v6-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-700italic - latin */
@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 700;
  src: local('Noto Serif Bold Italic'), local('NotoSerif-BoldItalic'),
       url('../Fonts/noto-serif-v6-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../Fonts/noto-serif-v6-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-700 - latin */
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Serif Bold'), local('NotoSerif-Bold'),
       url('../Fonts/noto-serif-v6-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../Fonts/noto-serif-v6-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Fontawesome 5
$fa-font-path: '../Fonts';
@import "fontawesome.scss";
@import 'brands';
@import 'regular';
@import 'solid';

@font-face {
  font-family: InfoDisplayWeb-Bold;
  src: url("../Fonts/displayInfoWeb/InfoDisplayWeb-Bold.woff") format("woff");
}
@font-face {
  font-family: InfoDisplayWeb-Book;
  src: url("../Fonts/displayInfoWeb/InfoDisplayWeb-Book.woff") format("woff");
}
@font-face {
  font-family: InfoDisplayWeb-Medium;
  src: url("../Fonts/displayInfoWeb/InfoDisplayWeb-Medium.woff") format("woff");
}
